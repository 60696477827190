.input-select-component {
  position: relative;
  padding: 0 24px;
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222;
  background-color: #f5f5f3;
  border: none;
  border-radius: 50px;
  margin: 12px 0;
  input {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    z-index: 5;
    background-color: #f5f5f3;
    border: none;
    font-family: Poppins;
    font-size: 16px;
    &::placeholder {
      color: var(--colorBlack);
      opacity: 1;
    }
  }
  &:disabled {
    opacity: "0.5";
  }

  .placeholder {
    position: relative;
    width: 100%;
    &.active {
      z-index: 5;
      svg {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }

  svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }

  .options-container {
    overflow: hidden;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 58px;
    padding-left: 12px;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 24px;
    box-shadow: 0 0 5px;
    background-color: #f5f5f3;

    .option {
      padding: 12px 1rem;
      cursor: pointer;
      &:hover {
        background: var(--colorLightestBlack);
      }
    }
  }
}
