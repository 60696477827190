.custom-select-container {
  // display: inline-block;
  // min-width: 150px;
  text-align: center;
  position: relative;
}

.selected-text {
  // background-color: #47eded;
  padding: 6px 32px 6px 12px;
  // border-bottom: 1px solid #37b0b0;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 10px;
    height: 18px;
    width: 30px;
    object-fit: contain;
  }
}

.selected-text::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 16px;
  border: 7px solid transparent;
  border-color: var(--primary) transparent transparent transparent;
}

.selected-text.active::after {
  top: 8px;
  border-color: transparent transparent var(--primary) transparent;
}

ul {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.select-options {
  position: absolute;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

li {
  list-style-type: none;
  padding: 6px 32px 6px 12px;
  background: #fff;
  border-bottom: 1px solid var(--primaryLight);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:last-of-type {
    border-bottom: none;
  }
  img {
    margin-right: 10px;
    height: 18px;
    width: 30px;
    object-fit: cover;
  }
}

li:hover {
  background-color: var(--secondary);
  color: #ffffff;
}
