.expired-notice {
  text-align: center;
  padding: 0.5rem;
}

.expired-notice > span {
  font-size: 1rem;
  font-weight: bold;
  color: crimson;
  font-size: 0.8rem;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.75rem;
  padding: 1rem;
  text-decoration: none;
  color: var(--primary);
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.1rem 0 0.1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: var(--danger);
}
.show-counter .countdown.warning {
  color: var(--warning);
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}
