.side-section {
  width: 15%;
  border-right: 1px solid #e2e2e2;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden;
  position: fixed;

  @media screen and (max-width: 1200px) {
    width: 25%;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }

  .input-container {
    position: relative;

    .whatsapp-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 8%;
      transform: translateY(-50%);
    }
  }
  .input-phone-number {
    padding: 10px 4px 10px 5%;
    background-color: var(--secondary);
    width: 100%;
    border-radius: 50px;
    border: 2px solid #000;
    outline: none;
    text-align: center;
    font-family: "Poppins", sans-serif;
    &::placeholder {
      color: #000;
      padding-left: 10%;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .brand-container {
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    color: #fff;
    @media screen and (max-width: 1600px) {
      padding: 16px 0;
    }
  }
  .return-container {
    padding: 32px 10%;
    border-top: 1px solid #e2e2e2;
    @media screen and (max-width: 1600px) {
      padding: 24px 10%;
    }
  }
}

.whatsapp-container {
  background-color: var(--secondary);
  padding: 16px 10%;
  border-top: 1px solid #e2e2e2;

  // margin-top: 12px;
}
