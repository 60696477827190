.mobile-header {
  // background-color: #000;
  background-color: var(--toggleSectionIconColor);
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    padding: 32px 5%;
  }
}
