// FONT
@import "./font/font.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: "Poppins", sans-serif;
}
.mt-05 {
  margin-top: 0.5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mb-05 {
  margin-bottom: 0.5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}

.title-style {
  font-weight: 600;
  font-size: 24px;
  padding-left: 32px;
  color: var(--primary);
  @media screen and (max-width: 576px) {
    font-size: 20px;
    padding-left: 0;
  }
  &-no-padding {
    padding-left: 0;
  }
}
.title-style-no-padding {
  font-weight: 600;
  font-size: 24px;
  padding-left: 0;
  color: var(--primary);
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
}
.link-style {
  text-decoration: none;
  color: inherit;
  width: 350px;
  &.disabled {
    pointer-events: none;
  }
}
.checkbox-style {
  flex: 1;
  transform: scale(2);
  @media screen and (max-width: 576px) {
    transform: scale(1.2);
  }
}
.checkbox-label {
  text-transform: uppercase;
  flex: 20;
  margin-left: 8px;
  @media screen and (max-width: 576px) {
    flex: 10;
    margin-left: 0px;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  &.title-container {
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.main-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}
.title-container {
  padding: 24px 5% 24px;
  border-bottom: 1px solid #e2e2e2;
  @media screen and (max-width: 768px) {
    border-bottom: none;
    padding: 24px 5% 0;
  }
  &.hide-title {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
.desktop-btn,
.only-desktop {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.only-desktop-lg {
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.padding-desktop {
  padding-left: 32px;
  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
}

.input-icon {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.right-container {
  position: relative;
  margin-left: 15%;
  border-top: 1px solid #e2e2e2;
  width: 85%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f3f5f4;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @media screen and (max-width: 1200px) {
    width: 75%;
    margin-left: 25%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: 100vh;
    margin-left: 0%;
  }
}
.right-container-noscroll {
  position: relative;
  margin-left: 15%;
  border-top: 1px solid #e2e2e2;
  width: 85%;
  display: flex;
  flex-direction: column;
  background-color: #f3f5f4;

  @media screen and (max-width: 1200px) {
    width: 75%;
    margin-left: 25%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0%;
  }
}

select:disabled {
  opacity: 0.5 !important;
}

.btn {
  background-color: var(--primary);
  color: #fff;
  padding: 12px;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  max-width: 350px;
  width: 100%;
  margin-top: 24px;

  &:hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem 0;
  }
}

.st0Logo {
  fill: var(--primary);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.btn-mobile-style {
  background-color: var(--primary);
  color: #fff;
  padding: 6px 12px;
  border-radius: 1rem;
  text-decoration: none;
  &:hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem 0;
  }
}
.error-container {
  color: var(--primary);
  padding: 1.5rem 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: calc(100vh - 90px);
  font-size: 1.5rem;
}
.return-complete-popup {
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary);

  & > h3 {
    margin: 1rem 0;
    font-size: 1.8rem;
    text-align: center;
  }
  & > p {
    text-align: center;
  }
  & > a {
    background-color: var(--primary);
    color: #fff;
    padding: 12px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    max-width: 350px;
    width: 100%;

    margin-top: 24px;

    &:hover {
      opacity: 0.5;
    }
    @media screen and (max-width: 768px) {
      margin: 1rem 0;
    }
  }
}
.flex-column-mobile {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
}
