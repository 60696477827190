.tracking-container {
  width: 66%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.tracking-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 2rem;
  @media screen and (max-width: 576px) {
    margin-bottom: 1rem;
    // display: none;
  }
  .tracking-line-icon {
    display: flex;
    .st0icon {
      fill: var(--textInSecondaryColor);
      // stroke: var(--textInSecondaryColor);
    }
  }
  .tracking-steps {
    position: relative;
    display: flex;
    align-items: center;
    //     justify-content: end;
    flex: 1;
  }

  .tracking-dot {
    z-index: 15;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (max-width: 576px) {
      height: 40px;
      width: 40px;
    }
  }
  .line {
    position: absolute;
    left: 50%;
    top: calc(24px - 2px);
    height: 4px;
    width: 100%;
    @media screen and (max-width: 576px) {
      top: calc(20px - 2px);
    }
  }
  .tracking-title {
    font-size: 0.8rem;
    padding-top: 4px;
    @media screen and (max-width: 576px) {
      height: 16px;
      font-size: 0.52rem;
      text-align: center;
      display: none;
      // width: 100%;
    }
  }
  .apex-dot {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    transform: translate(25%, -25%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tracking-toggle {
  background-color: #fff;
  border-radius: 1rem;
  margin-bottom: 0.6rem;
  box-shadow: 1px 1px 3px #d3d3d3;
  &:last-child {
    margin-bottom: 0;
  }
  cursor: pointer;
  &.disabled {
    opacity: 0.3;
    cursor: initial;
    pointer-events: none;
  }
  .section-row {
    color: var(--primary);
    display: flex;
    padding: 1.2rem 2rem;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 576px) {
      padding: 0.8rem 1rem;
    }
  }
  .tracking-toggle-title {
    font-weight: 600;
    font-size: 0.9rem;
    text-align: center;
    @media screen and (max-width: 400px) {
      font-size: 0.75rem;
      // margin-bottom: 28px;
    }
  }
  .tracking-toggle-title-popup {
    flex: 5;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    padding-left: 12px;
    // margin-bottom: 32px;
    @media screen and (max-width: 576px) {
      font-size: 0.9rem;
      // margin-bottom: 28px;
    }
    @media screen and (max-width: 400px) {
      font-size: 0.75rem;
      flex: 4;
      // margin-bottom: 28px;
    }
  }
  .tracking-toggle-subtitle {
    font-weight: 400;
    font-size: 0.8rem;
    color: var(--primary);
    @media screen and (max-width: 576px) {
      font-size: 0.7rem;
      padding: 0 8px;
    }

    // background-color: aquamarine;
  }
  .tracking-toggle-dot {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (max-width: 576px) {
      height: 32px;
      width: 32px;
    }
  }
  .toggle-section {
    // width: 80%;
    // margin: auto;

    font-size: 0.8rem;
    .section-row > div {
      flex: 1;
      // text-align: center;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
      display: none;
    }
  }
  .toggle-section-prov {
    // width: 80%;
    // margin: auto;

    font-size: 0.8rem;
    .section-row > div {
      flex: 1;
      // text-align: center;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
  .toggle-section-mobile {
    display: none;
    color: var(--primary);
    padding-top: 1rem;
    // width: 80%;
    // margin: auto;
    // & > div {
    //   margin-bottom: 1rem;
    // }
    font-size: 0.8rem;

    .section-row > div {
      flex: 1;
      // text-align: center;
    }

    .toggle-section-date-mobile {
      font-size: 0.7rem;
    }
    .toggle-section-label-mobile {
      font-size: 0.9rem;
      font-weight: 500;
      margin: 0;
    }
    .toggle-section-mobile-warehouse {
      font-size: 0.7rem;
      font-weight: normal;
    }
    @media screen and (max-width: 576px) {
      width: 100%;
      display: block;
    }
  }
}
