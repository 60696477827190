.flag-container {
  // position: absolute;
  // top: 5%;
  // right: 5%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 576px) {
    // top: 16px;
    // right: 8px;
  }
}

.flag {
  color: var(--primaryLight);
  cursor: pointer;
  min-width: 30px;
  padding: 0 12px;
  &:first-of-type {
    border-right: var(--primary) solid 1px;
  }
  &.active {
    color: var(--primary);
    font-weight: 600;
  }
}
