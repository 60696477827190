.error-container {
  color: var(--primary);
  padding: 1.5rem 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: calc(100vh - 90px);
  font-size: 1.5rem;
}

.list-container {
  padding: 1.5rem 0;
  width: 90%;
  margin: 0 auto;

  .list-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  .shipments-list {
    margin-bottom: 4rem;
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      font-size: 0.8rem;
      font-weight: 600;
      padding: 0 16px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .table-header-item {
      flex: 1;
      text-align: center;
      @media screen and (max-width: 768px) {
        text-align: start;
        font-size: 0.7rem;
        display: flex;
        align-items: center;
        min-height: 34.5px;
      }
    }
    .table-row {
      background-color: #fff;
      width: 100%;
      display: flex;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      color: var(--primary);
      margin-bottom: 8px;
      min-height: 56px;

      @media screen and (max-width: 768px) {
        margin-bottom: 16px;
      }

      .inbox-title-list {
        display: none;
        font-weight: 600;
        font-size: 0.8rem;

        @media screen and (max-width: 768px) {
          display: block;
          width: 50%;
        }
      }
      .data-list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 0.8rem;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          width: 50%;
          justify-content: center;
        }
      }
      .data-cell {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        @media screen and (max-width: 768px) {
          justify-content: flex-end;
          text-align: end;
          font-size: 0.7rem;
          min-height: 30px;
        }
      }
    }
  }
}
.btn-mobile-style {
  background-color: var(--primary);
  color: #fff;
  padding: 6px 12px;
  border-radius: 1rem;
  text-decoration: none;
  &:hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem 0;
  }
}
