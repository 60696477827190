:root {
  --primary: #504c53;
  --secondary: #c8102e;
  --primaryLight: #504c5340;
  --checkColor: #c8102e;
  --textSecondaryBtnColor: #504c53;
  --bgSecondaryBtnColor: #fff;
  --textInSecondaryColor: #fff;
  --warning: #ff7f00;
  --danger: #ff7f00;
  --toggleSectionIconColor: #c8102e;
  --magnifyingGlassColor: #fff;
  --envelopeColor: #504c53;
}
