.sidebar-nav {
  scrollbar-width: auto !important;
  scrollbar-color: var(--primary) var(--secondary) !important;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #303c54 !important;
  }
  &::-webkit-scrollbar-track {
    background: var(--secondary) !important;
  }
}

.half-width {
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.distance-hours {
  display: flex;
  margin-left: 16;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-top: 1rem;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
}
.selected-pin {
  background-color: var(--primary) !important;
  color: var(--secondary) !important;
  transform: scale(1.2);
}
