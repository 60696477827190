.tracking-section {
  //   padding: 0 5%;
  display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  background-color: #fff;
  width: 100%;
  height: 100%;
  color: var(--primary);
  @media screen and (max-width: 1040px) {
    flex-direction: column;
    align-items: center;
  }

  .half-section {
    display: flex;
    width: 50%;
    flex-direction: column;
    //     justify-content: center;
    align-items: center;
    padding-top: 4rem;
    &:first-of-type {
      border-right: 1px solid #e6e6e6;
      @media screen and (max-width: 1040px) {
        border-right: none;
        border-bottom: 1px solid #e6e6e6;
      }
    }
    @media screen and (max-width: 1040px) {
      width: 100%;
      padding-left: 7%;
      padding-right: 7%;
      padding-top: 2rem;
    }
  }
}
.request-otp-text {
  font-size: 0.7rem;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
