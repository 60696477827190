.deliveryDate {
  padding: 8px 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary);
}

.custom-message {
  & * {
    background-color: var(--toggleSectionIconColor) !important;
    font-family: "Poppins", sans-serif !important;
  }
}
.custom-message-card {
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column !important;
  }
}

.custom-message-title {
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.tracking-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}
.card-section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 30%;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.tracking-step {
  display: flex;
  min-height: 100px;

  .tracking-step-right {
    flex: 15;
    @media screen and (max-width: 1200px) {
      flex: 10;
    }
    @media screen and (max-width: 768px) {
      flex: 5;
    }
  }
  .tracking-step-title {
    font-size: 16px;
  }
  .tracking-step-data {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 300;
    color: #11111190;
  }
}

.address-lines {
  line-height: 1.7;
  font-size: 16px;
  color: var(--primary);
  & > div {
    text-transform: capitalize;
  }
}

.main-header {
  background-color: #fff;
  border-bottom: 1px solid #e2e2e2;
  height: 80px;
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .container-logo {
    max-width: 50%;
    display: flex;

    .logo {
      width: 80%;
    }
  }
  // position: fixed;
  // width: 100%;
}

.pod-container {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
  .pod-map {
    width: 55%;
    position: relative;
    @media screen and (max-width: 576px) {
      width: 100%;
      height: 240px;
    }
  }
  .pod-data {
    width: 40%;
    color: var(--primary);
    @media screen and (max-width: 576px) {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

// ONLY MOBILE
.input-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  .whatsapp-icon,
  .input-icon {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.input-phone-number {
  padding: 12px 5% 12px 12%;
  width: 200%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border: 2px solid #000;
  border-right: 2px solid var(--secondary);
  background-color: var(--secondary);
  color: #000;
  outline: none;
  font-family: "Poppins", sans-serif;
  &::placeholder {
    color: #000;
    font-size: 12px;
    font-weight: 500;
  }
}

.mobile-buttons-container {
  display: none;
  @media screen and (max-width: 576px) {
    // padding-top: 16px;
    display: flex;
    flex-direction: column;
  }
}
.mobile-whatsapp {
  background-color: var(--secondary);
  color: #000;
  padding: 16px 5% 32px;
}
