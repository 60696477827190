.card-full-size {
  width: 100%;
}

.card-half-size {
  width: 48%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
